import { disableFuelTypes, enableFuelTypes } from '@/api/aqfueltypes';

const disableFuelType = {
	id: 'disableFuelType',
	selectionType: 'multiple',
	label: 'aqfueltypes.actions.disableFuelType',
	functionality: 'UPDATE_AQFUELTYPES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqfueltypes.actions.disableFuelType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqfueltypesid: registries[i].aqfueltypesid
			});
		}

		const data = await disableFuelTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableFuelType = {
	id: 'enableFuelType',
	selectionType: 'multiple',
	label: 'aqfueltypes.actions.enableFuelType',
	functionality: 'UPDATE_AQFUELTYPES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqfueltypes.actions.enableFuelType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqfueltypesid: registries[i].aqfueltypesid
			});
		}

		const data = await enableFuelTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableFuelType, enableFuelType]
};
